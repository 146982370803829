import { FC } from 'react'
import KvSvg from 'src/assets/svg/kv.svg'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { size, color } from 'src/utils/constants'

type Props = {
  heading: string
  id?: string
}

export const SiteHeading: FC<Props> = ({ heading }) => {
  return (
    <header css={rootStyle}>
      <h1>{heading}</h1>
      <div css={kvStyle}>
        <KvSvg />
      </div>
    </header>
  )
}

const rootStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > h1 {
    font-size: ${size.font.h3}rem;
    max-width: max-content;
    margin-right: auto;
    @media (min-width: ${size.breakPoint.s}px) {
      font-size: 2.4rem;
    }
  }
`

const kvStyle = css`
  position: relative;
  &::before {
    display: block;
    content: '';
    width: 0;
    height: 30%;
    border-left: ${size.border.s}rem dotted ${color.border};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(-30deg);
    transform-origin: bottom;
  }
  &::after {
    display: block;
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: calc(30% + (${size.border.s}rem * 2));
    left: calc(${size.border.s}rem * 5);
    right: 0;
    margin: auto;
    border: ${size.border.s}rem solid ${color.border};
    border-radius: 50%;
  }
`
