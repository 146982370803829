import { FC } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { Layout } from 'src/components/site/Layout'
import { Section } from 'src/components/parts/Section'
import { Container } from 'src/components/parts/Container'
import { FlickGrid } from 'src/components/parts/FlickGrid'
import { Paragraph } from 'src/components/parts/Paragraph'
import { PanelGrid } from 'src/components/parts/PanelGrid'
import { SiteHeading } from 'src/components/parts/SiteHeading'
import { SectionHeading } from 'src/components/parts/SectionHeading'
import { ServicePanel } from 'src/components/parts/ServicePanel'
import { PostLink } from 'src/components/parts/PostLink'
import { TextLink } from 'src/components/parts/TextLink'
import { Text } from 'src/components/parts/Text'
import IdeaSvg from 'src/assets/svg/idea.svg'
import SiteSvg from 'src/assets/svg/site.svg'
import ContentsSvg from 'src/assets/svg/contents.svg'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

type Props = {
  data: GatsbyTypes.NewPostsQuery
}

const IndexPage: FC<Props> = ({ data }) => {
  return (
    <Layout title="ECサイト・コーポレートサイト制作" ldType="WebSite">
      <Section>
        <Container small css={{ width: '90%' }}>
          <SiteHeading heading="ウェブのこのへんやります。" />
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionHeading
            heading="KOKONIARUKOTO.?"
            subHeading="ここにあること？"
          />
          <Paragraph>
            <Text text="ECサイトの制作が得意ですが、コーポレートサイトの制作やECサイトの運営・企画のサポートなども承っています。" />
            <Text text="売りたいものを売るための、やりたいことをやるための、画面の向こうで完成するものづくりを目指しています。" />
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionHeading heading="SERVICE" subHeading="できること" />
          <FlickGrid>
            <ServicePanel
              svgImage={<IdeaSvg />}
              heading="EC企画・運営"
              text="ECビジネスのスタートアップに必要なターゲティング、商品企画、立ち上げ後の運営代行や顧客対応に至るまで、ご要望に合わせてワンストップでサポートをします。"
            />
            <ServicePanel
              svgImage={<SiteSvg />}
              heading="WEBサイト制作"
              text="一般的なホームページはもちろん、楽天市場やYahoo!ショッピング、自社ECサイトの制作など、多種多様な目的、プラットフォームに合わせた制作が可能です。"
            />
            <ServicePanel
              svgImage={<ContentsSvg />}
              heading="コンテンツ制作"
              text="ブランディング用、YouTube用の動画編集やメディアサイトの記事作成など、WEBサイトの構築と合わせてコンテンツマーケティングに必要な制作をします。"
            />
          </FlickGrid>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionHeading heading="BLOG" subHeading="記事" />
          <PanelGrid>
            {data.allMdx.edges.map(({ node }) => {
              const posts = node.frontmatter
              // @ts-ignore
              const image = getImage(posts?.thumbnail)
              return (
                <PostLink
                  key={node.id}
                  slug={posts?.slug}
                  heading={posts?.title}
                  tag={posts?.tag}
                  image={image}
                />
              )
            })}
          </PanelGrid>
          <Paragraph>
            <TextLink
              to="/blog/"
              text="もっと見る"
              css={{ marginRight: 'auto' }}
            />
          </Paragraph>
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query NewPosts {
    allMdx(limit: 6, sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          frontmatter {
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
            slug
            tag
            title
          }
        }
      }
    }
  }
`

export default IndexPage
