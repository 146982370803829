import { FC, ReactElement } from 'react'
import { Text } from 'src/components/parts/Text'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { size, color } from 'src/utils/constants'

type Props = {
  svgImage: ReactElement
  heading: string
  text: string
}

export const ServicePanel: FC<Props> = ({
  svgImage: SvgImage, //eslint-disable-line @typescript-eslint/naming-convention
  heading,
  text,
}) => {
  return (
    <div css={rootStyle}>
      <div css={svgStyle}>{SvgImage}</div>
      <h3 css={headingStyle}>{heading}</h3>
      <Text text={text} />
    </div>
  )
}

const rootStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem 0;
  max-width: calc((${size.width.m}px - (${size.gutter.s}rem * 4)) / 3);
  width: 85%;
  flex-shrink: 0;
  align-self: flex-start;
`

const svgStyle = css`
  padding: ${size.gutter.s}rem;
  border: ${size.border.s}rem solid ${color.border};
  & > svg {
    max-width: 250px;
    width: 50%;
    margin: 0 auto;
  }
`

const headingStyle = css`
  font-size: ${size.font.h3}rem;
  text-align: center;
  border-bottom: ${size.border.s}rem dotted ${color.border};
`
